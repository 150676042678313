$('.js-burger').on('click', () => {
  $('body').toggleClass('is-header-menu-opened')
})

$('.header-menu__link').on('click', () => {
  $('body').removeClass('is-header-menu-opened')
})

$(window).on('scroll load resize', () => {
  const scrolled = $(window).scrollTop()
  const ww = $(window).width()
  let offset = ww > 1280 ? 40 : 30

  if (ww <= 767) {
    offset = 16
  }

  if (scrolled > offset) {
    $('.header').addClass('header_is_sticky')
  } else {
    $('.header').removeClass('header_is_sticky')
  }
})
